/**
 * defaultLanguage {string} send empty string to pick browserlang (or 'fi' , 'en')
 * theme {object} object of hex colors (primary: "#8fc852",secondary: "#f28100")
 */

// ===========================================================================================
// KH24-BETA STORE
// ===========================================================================================

export const config = {
  version: "2.4.0",
  name: "kh24-beta",
  tenant: "kh24-beta",
  title: "Kauppahalli24-Beta",
  favicon: "assets/favicon-kh24.ico",
  defaultLanguage: "fi",
  auth: {
    socialLogin: {
      visible: false,
    },
    guestUser: {
      allowCheckout: false,
    },
    smsPasswordRecovery: true,
  },
  navbar: {
    logo: {
      label: "Kauppahalli24-Beta",
      src: "assets/images/KH24BETA-logo.png",
    },
    logo1: {
      label: "",
      src: "",
    },
    dashboard: {
      visible: false,
    },
    menu: {
      visible: false,
      menuPages: [
        {
          title: "NÄIN SE TOIMII",
          link: "/",
          externalLink: "",
        },
        {
          title: "TARINAT",
          link: "/",
          externalLink: "",
        },
        {
          title: "TUOTTAJAT",
          link: "/",
          externalLink: "",
        },
      ],
    },
    recipe: {
      visible: true,
    },
  },
  searchBar: {
    isSearchFiltersVisible: true,
  },
  dashboard: {
    visible: true,
    profile: {
      visible: true,
    },
    addresses: {
      visible: true,
    },
    orders: {
      visible: true,
    },
    refunds: {
      visible: true,
    },
  },
  theme: {
    primary: "#7A44D0",
    secondary: "#ffe6e1",
    headerBg: "#f2f2f2",
    headerFont: "#000000",
    footerBg: "#303030",
    footerFont: "#ffffff",
    fontFamily: "urbane",
    fontTitle: "urbane",
    bodyBgColor: "#F2F2F2",
    textColor: "#3c3c3c",
    fontPrice: "urbane",
    priceDiscountTextColor: "#7d7d7d",
    buyButtonBgColor: "#93cb63",
    buyButtonIcon: "icon-add",
    buyButtonColor: "#fff",
    buyButton2BgColor: "#FFC036",
  },
  sameDayFreeDelivery: {
    status: true,
  },
  availableDeliveryMethods: {
    noOfDeliveryMethods: 3,
    deliveryMethods: ["CHD001", "PO2103", "PO2104"],
    deliveryLeadTime: 2,
  },
  visible: {
    dateTime: true,
    favorites: false,
  },
  parentCompany: {
    en: "Kauppahalli24 Beta ",
    fi: "Kauppahalli24 Beta ",
  },
  hasSearchTags: false,
  homePage: {
    isAgeConfirmationPopup: false,
    isDynamic: true,
    contentURL: "/wp/blocks/block-shop-front",
  },
  footer: {
    contentURL: "/wp/blocks/section-footer",
  },
  pricingCampaign: {
    deliveryDiscountRanges: [
      {
        cartPriceFrom: 0,
        cartPriceTo: 50,
        discount: 2,
        zoneCharges: [],
      },
      {
        cartPriceFrom: 50,
        cartPriceTo: 100,
        discount: 5,
        zoneCharges: [],
      },
    ],
  },
  dynamicPages: {
    terms: { title: "Toimitusehdot", link: "/page/toimitusehdot" },
    policy: { title: "Tietosuojaseloste", link: "/page/tietosuojaseloste" },
  },
  multiplePromoCodes: {
    status: false,
  },
  checkoutPage: {
    phone: "040 126 2979",
    email: "asiakaspalvelu@kauppahallli24.fi",
    isPickupLocationSectionVisible: false,
    isShippingSectionVisible: true,
    isPromoCodeSectionVisible: true,
    billingSection: {
      isBillingAddressesSectionVisible: false,
      isBillingSectionOpenByDefault: false,
    },
  },
  signup: {
    isBirthDateField: true,
  },
  localization: {
    ZIP_POPUP_ENTER_TEXT: "ZIP.Z001",
  },
};
